import { Link } from "react-router-dom";
import vender from "../imgs/vender.webp";
import { Input } from "rsuite";
import btnStyle from "../btnStyle.module.css";
import { useZorm } from "react-zorm";
import { sellContactFormSchema } from "../contact.schema";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

export function Sell() {
  const [emailLoading, setEmailLoading] = useState(false);

  useEffect(() => emailjs.init("6KUX_UqfosafJ4VF5"), []);

  const zorm = useZorm("sellContact", sellContactFormSchema, {
    async onValidSubmit(event) {
      event.preventDefault();
      //email rd-station
      const sendData = {
        from_name: event.data.name,
        from_email: event.data.email,
        phone: event.data.phone,
        address: event.data.address,
        number: event.data.number,
        city: event.data.city,
        state: event.data.state,
        complement: event.data.complement,
        propertie_address: event.data.propertie_address,
        propertie_number: event.data.propertie_addressNumber,
        propertie_city: event.data.propertie_city,
        propertie_state: event.data.propertie_state,
        propertie_complement: event.data.propertie_complement,
      };

      setEmailLoading(true);

      try {
        emailjs.send("service_a75p5lo", "template_dr6wkfj", sendData).then(
          (result) => {
            toast.success("Email enviado com sucesso!");
            setEmailLoading(false);
          },
          (error) => {
            toast.error("Tente novamente em instantes...");
            setEmailLoading(false);
          }
        );
      } catch (e) {
        console.log(e);
      }
      return event.target.reset();
    },
  });

  const contactDisabled = zorm.validation?.success === false;

  return (
    <>
      {/* <Helmet>
        <meta name="description" content="Vender" />
      </Helmet> */}
      <section className="flex items-center justify-center mx-[20%] text-[#393939] mt-40 mb-10 max-md:flex-col max-md:ml-10 max-md:mr-0 max-md:mb-0 max-md:mt-32 min-[2200px]:mx-[30%]">
        <div className="mr-10 space-y-4">
          <h2 className="pb-4 mr-10 border-b-2">
            Vantagens de estar na Nossa Casa Imóveis
          </h2>
          <p className="text-base font-medium">
            Aproveite os benefícios de anunciar seu imóvel na Nossa Casa
            Imóveis. Contamos com uma equipe especializada em gerência de
            vendas, marketing e departamento jurídico exclusivo, além de
            consultoria imobiliária personalizada em cada negócio. <br />
            <br />
            Nossos profissionais experientes e qualificados no mercado
            imobiliário de Torres e Itapema, garantem um atendimento exclusivo,
            com fotos e vídeos profissionais dos empreendimentos.
            <br />
            <br /> Após o cadastro, um de nossos agenciadores entrará em contato
            para obter todas as informações necessárias e garantir o melhor
            desempenho na venda do seu imóvel.
            <br />
            <br />
            <b>
              Atendemos na região de Torres e Itapema. Confie em quem entende do
              mercado imobiliário. <br />
              Confie na Nossa Casa Imóveis.
            </b>
          </p>
        </div>
      </section>
      <section className="flex items-center justify-center mx-[20%] mb-[-10rem] max-md:flex-col max-md:ml-10 max-md:mr-0 max-md:mb-[-4rem] min-[2200px]:mx-[30%]">
        <div className="max-md:pt-10 max-md:mr-8">
          <img
            className=" rounded-xl max-md:max-w-[20rem] shadow-2xl"
            src={vender}
          />
        </div>
      </section>
      <section className="bg-[#F1F1F1] p-10 pt-56 space-y-8 pb-32 max-md:pb-24 max-md:pt-28">
        <div>
          <h2 className="text-[#A81F24] flex justify-center max-md:text-center">
            Dicas para atingir o sucesso na venda
          </h2>
        </div>
        <div className="flex mx-44 justify-evenly max-md:flex-col max-md:mx-0 max-md:space-y-5 max-md:items-center min-[2200px]:mx-[25%]">
          <div className="w-72">
            <h6 className="text-[#393939] m-3 p-3 rounded-xl text-center border-solid border-[#393939] border-2 shadow-lg">
              Acelere a venda
            </h6>
            <p className="text-[#393939] font-semibold pt-5 text-justify">
              Anúncios completos geram maior interesse entre os clientes online.
              Em um ambiente competitivo como a internet, detalhes são cruciais
              para destacar seu imóvel. Preencha todas as informações do
              cadastro para aumentar significativamente as chances de venda.
            </p>
          </div>
          <div className="w-72">
            <h6 className="text-[#393939] m-3 p-3 rounded-xl text-center border-solid border-[#393939] border-2 shadow-lg">
              Dicas de Fotografia
            </h6>
            <p className="text-[#393939] font-semibold pt-5 text-justify">
              As fotos são o primeiro contato do cliente com seu imóvel, e uma
              boa impressão é essencial. No dia das fotos, organize o espaço,
              removendo objetos desnecessários e mantendo o ambiente limpo e
              atraente. Imagens de qualidade podem triplicar o retorno de
              clientes interessados.
            </p>
          </div>
          <div className="w-72">
            <h6 className="text-[#393939] m-3 p-3 rounded-xl text-center border-solid border-[#393939] border-2 shadow-lg">
              Facilite a negociação
            </h6>
            <p className="text-[#393939] font-semibold pt-5 text-justify">
              A segurança na negociação é fundamental. Certifique-se de ter uma
              cópia da matrícula do imóvel atualizada, além das Certidões
              Negativas de Débitos (CND) do IPTU e do condomínio. A rapidez na
              obtenção desses documentos pode acelerar o processo de
              agenciamento e venda do seu imóvel.
            </p>
          </div>
        </div>
      </section>
      <section className="flex justify-center pb-20 max-md:mx-0 max-md:pb-10">
        <div className="border-2 rounded-2xl max-w-xl mt-[-50px] bg-white max-md:mx-2 shadow-xl">
          <form
            className="flex flex-col px-20 pt-10 gap-y-2 max-md:px-10"
            ref={zorm.ref}
          >
            <h3 className="text-center text-[#A81F24] max-md:text-left">
              Cadastre-se para colocar seu imóvel à venda
            </h3>
            <br />
            <h6>Dados Pessoais</h6>
            <Input
              placeholder="Nome Completo*"
              name={zorm.fields.name()}
              id="name"
            />
            {zorm.errors.name((error) => {
              return (
                <span className="px-1 text-sm text-[#A81F24]">
                  {error.message}
                </span>
              );
            })}
            <div className="flex">
              <Input
                placeholder="Telefone*"
                className="mr-2"
                id="phone"
                name={zorm.fields.phone()}
                type="number"
                pattern="[0-9]*"
              />

              <Input
                placeholder="Email*"
                name={zorm.fields.email()}
                id="email"
              />
            </div>
            <div className="flex justify-between">
              {zorm.errors.email((error) => {
                return (
                  <span className="px-1 text-sm text-[#A81F24]">
                    {error.message}
                  </span>
                );
              })}

              {zorm.errors.phone((error) => {
                return (
                  <span className="px-1 text-sm text-[#A81F24]">
                    {error.message}
                  </span>
                );
              })}
            </div>
            <Input
              placeholder="Endereço*"
              name={zorm.fields.address()}
              id="adress"
            />

            <div className="flex">
              <Input
                placeholder="Número*"
                id="adress-number"
                className="w-24 mr-2"
                name={zorm.fields.number()}
              />
              <Input
                placeholder="Cidade*"
                id="city"
                className="mr-2"
                name={zorm.fields.city()}
              />
              <Input
                placeholder="Estado*"
                name={zorm.fields.state()}
                id="state"
              />
            </div>
            <Input
              placeholder="Complemento"
              name={zorm.fields.complement()}
              id="complement"
            />
            {zorm.errors.address((error) => {
              return (
                <span className="px-1 text-sm text-[#A81F24]">
                  {error.message}
                </span>
              );
            })}
            <br />
            <h6>Dados do Imóvel</h6>
            <Input
              placeholder="Endereço*"
              id="property-adress"
              name={zorm.fields.propertie_address()}
            />
            <div className="flex">
              <Input
                placeholder="Número*"
                id="property-number"
                className="w-24 mr-2"
                name={zorm.fields.propertie_addressNumber()}
              />
              <Input
                placeholder="Cidade*"
                id="property-city"
                className="mr-2"
                name={zorm.fields.propertie_city()}
              />
              <Input
                placeholder="Estado*"
                id="property-state"
                name={zorm.fields.propertie_state()}
              />
            </div>
            <Input
              placeholder="Complemento"
              id="property-complement"
              name={zorm.fields.propertie_complement()}
            />
            {zorm.errors.propertie_address((error) => {
              return (
                <span className="px-1 text-sm text-[#A81F24]">
                  {error.message}
                </span>
              );
            })}
            <span>* Campos obrigatórios</span>
            <br />
            <div className="flex justify-center pb-10">
              <button
                disabled={contactDisabled}
                className={`${
                  btnStyle.button2
                } w-fit px-48 py-2 bg-[#A81F24] font-semibold text-base rounded-lg max-md:px-28 ${
                  emailLoading ? "cursor-wait" : ""
                }`}
                type="submit"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
