import { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { arrowFoward, arrowPrev } from "../components/Arrows";
import { SelectPicker, TagPicker, Carousel as RsuiteCarousel } from "rsuite";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { api } from "../api";
import "rsuite/dist/rsuite.min.css";
import btnStyle from "../btnStyle.module.css";
import banner from "../imgs/banner.webp";
import bannerMob from "../imgs/banner-mob.webp";
import { ListAptos } from "../aptos.type";
import { blogPosts } from "../blog-post.type";
import { BiArea, BiSolidBed, BiSolidCarGarage } from "react-icons/bi";
import { RiLoader2Line, RiMapPin2Fill } from "react-icons/ri";
import { neighborhoodFilter, subtypesFilter } from "../components/FilterBar";
import QueryString from "qs";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet";
import axios from "axios";
import backgroundHomeImage from "../imgs/bg-home.webp";
import backgroundHomeImage2 from "../imgs/bg-home3.webp";

let listAptosExclusive: ListAptos;
let listAptosWithVideo: ListAptos;
let listAptosFurnished: ListAptos;
let listPost: blogPosts;

// let initialHomeNeighborhoodList: string[];
let initialHomeSubTypeList: string[];

//tipagem das variaveis para receber do server

interface Cities {
  city: string;
  city_id: number;
}

let infoCities: Cities[];

export function Home() {
  //variavel para receber do server
  // @ts-ignore
  const [infoCitiesList, setInfoCitiesList] = useState(infoCities);

  //cidade selecionada
  const [citiesList, setCitiesList] = useState<String>();

  const [isLoading, setIsLoading] = useState(false);
  const [blogLoading, setBlogLoading] = useState(false);
  const [infoAptosExclusive, setInfoAptosExclusive] =
    // @ts-ignore
    useState(listAptosExclusive);
  const [infoAptosWithVideo, setInfoAptosWithVideo] =
    // @ts-ignore

    useState(listAptosWithVideo);
  const [infoAptosFurnished, setInfoAptosFurnished] =
    // @ts-ignore

    useState(listAptosFurnished);

  const [homeSubTypeList, setHomeSubTypeList] = useState(
    // @ts-ignore

    initialHomeSubTypeList
  );
  // @ts-ignore

  const [listPosts, setListPosts] = useState(listPost);

  const query = QueryString;
  const [queryCitiesURL, setqueryCitiesURL] = useState("");
  const [querySubTypeURL, setQuerySubTypeURL] = useState("");

  async function getAptosFurnished() {
    const data = await api.get("/properties?&furnished=1");
    return setInfoAptosFurnished(data.data);
  }

  async function getAptosWithVideo() {
    const data = await api.get("/properties?&withVideo=true");
    return setInfoAptosWithVideo(data.data);
  }

  async function getAptosExclusive() {
    setIsLoading(true);
    const data = await api.get("/properties?&exclusive=true");
    return setInfoAptosExclusive(data.data);
  }

  async function getBlogPosts() {
    setBlogLoading(true);
    const data = await axios.get(
      "https://www.nossacasa.com.br/blog/wp-json/wp/v2/posts"
    );
    return setListPosts(data.data);
  }

  async function getInfoData() {
    const data = await api.get("/info");
    return setInfoCitiesList(data.data);
  }

  useEffect(() => {
    getInfoData();
    getAptosExclusive().then(() => {
      setIsLoading(false);
    });
    getAptosWithVideo();
    getAptosFurnished();
    getBlogPosts().then(() => {
      setBlogLoading(false);
    });

    // document
    //   .getElementsByTagName("meta")
    //   ?.namedItem("description")
    //   ?.setAttribute("content", "My Meta Description Here");
    // getBlogPosts().then(() => {
    //   setBlogLoading(false);
    // });
    return;
  }, []);

  useEffect(() => {
    const cityVerifie =
      citiesList === ""
        ? null
        : setqueryCitiesURL(
            query.stringify({ city: citiesList }, { indices: false })
          );
    return;
  }, [citiesList]);

  useEffect(() => {
    setQuerySubTypeURL(
      query.stringify(
        {
          subtype: homeSubTypeList,
        },
        { indices: false }
      )
    );
  }, [homeSubTypeList]);

  let finalQuery = querySubTypeURL + "&" + queryCitiesURL;

  const tagPickerWidthSubType = window.innerWidth > 768 ? 350 : 230;
  const tagPickerWidthNeighborhood = window.innerWidth > 768 ? 335 : 230;

  const carouselYoutubeMobile =
    window.innerWidth > 768
      ? {
          centerMode: true,
          percentage: 34,
          initial: 2,
          infinity: true,
          swipeable: false,
          arrows: true,
          initialPost: 1,
        }
      : {
          centerMode: true,
          percentage: 91.5,
          initial: 1,
          infinity: true,
          swipeable: true,
          arrows: false,
          initialPost: 1,
        };

  //define carrousel by city

  const [currentCarrousel, setCurrentCarrousel] = useState<number | undefined>(
    undefined
  );

  return (
    <>
      {/* <Helmet>
        <meta name="description" content="Página Inicial" />
      </Helmet> */}
      <div className=" mt-[6rem] max-md:mt-[5rem]" />
      <div
        className={`flex flex-col items-center justify-center min-h-[20rem] max-md:min-h-full`}
        id="image-home"
      >
        <RsuiteCarousel
          autoplay
          autoplayInterval={5000}
          className="absolute w-full bg-cover resize-none h-[500px] max-md:mt-[-90px]"
          activeIndex={currentCarrousel}
        >
          <img src={backgroundHomeImage} className="object-cover" />
          <img src={backgroundHomeImage2} className="object-cover" />
          <img src={backgroundHomeImage} className="object-cover" />
          <img src={backgroundHomeImage2} className="object-cover" />
          <img src={backgroundHomeImage} className="object-cover" />
        </RsuiteCarousel>
        {/* <Carousel
          showArrows={false}
          showIndicators={false}
          showThumbs={false}
          showStatus={false}
          autoPlay
          transitionTime={7000}
          infiniteLoop
          className="z-0"
          interval={10000}
          onClickItem={(e) => null}
        >
          <div>
            <img src={backgroundHomeImage} className="bg-cover resize-none" />
          </div>
          <div>
            <img src={backgroundHomeImage2} className="bg-cover resize-none" />
          </div>
          <div>
            <img src={backgroundHomeImage} />
          </div>
          <div>
            <img src={backgroundHomeImage2} className="bg-cover resize-none" />
          </div>
          <div>
            <img src={backgroundHomeImage} className="bg-cover resize-none" />
          </div>
          <div>
            <img src={backgroundHomeImage2} className="bg-cover resize-none" />
          </div>
          <div>
            <img src={backgroundHomeImage} className="bg-cover resize-none" />
          </div>
          <div>
            <img src={backgroundHomeImage2} className="bg-cover resize-none" />
          </div>
          <div>
            <img src={backgroundHomeImage} className="bg-cover resize-none" />
          </div>
          <div>
            <img src={backgroundHomeImage2} className="bg-cover resize-none" />
          </div>
        </Carousel> */}
        <div className="flex flex-col min-h-[30rem] items-center justify-center max-md:min-h-[40rem] max-md:justify-start max-md:pt-[6rem] z-40">
          <h3 className="text-center text-white max-md:text-2xl max-md:font-semibold max-md:mx-20">
            <p>Encontre seu imóvel</p>
          </h3>

          <div className="bg-[#FFFFFF] shadow-2xl border-solid border-[#8A9B9C] border-2 border-opacity-20 flex justify-around items-center mt-2 px-4 py-2 rounded-lg max-md:flex-col max-md:pt-7 max-md:pb-6 max-md:space-y-4 max-md:scale-100 max-md:mx-4 max-md:px-4">
            <div className="pr-1 max-md:pr-0">
              <SelectPicker
                className="home-picker"
                style={{
                  width: tagPickerWidthNeighborhood,
                  float: "right",
                }}
                menuStyle={{
                  width: tagPickerWidthNeighborhood,
                  float: "right",
                  zIndex: 100,
                }}
                onClean={() => {
                  setCitiesList("");
                  setqueryCitiesURL("");
                  setCurrentCarrousel(undefined);
                }}
                searchable={false}
                labelKey="city"
                valueKey="city_id"
                data={infoCitiesList}
                placeholder="Cidade"
                loading={isLoading}
                onChange={(item) => {
                  setCitiesList(String(item));
                  console.log(item);
                  if (item === 8242) {
                    setCurrentCarrousel(0);
                  } else if (item === 8509) {
                    setCurrentCarrousel(1);
                  } else if (item === 8622) {
                    setCurrentCarrousel(1);
                  } else if (item === 8596) {
                    setCurrentCarrousel(0);
                  } else if (item === 8507) {
                    setCurrentCarrousel(1);
                  } else if (item === 8396) {
                    setCurrentCarrousel(1);
                  } else if (item === 8357) {
                    setCurrentCarrousel(1);
                  } else {
                    setCurrentCarrousel(undefined);
                  }
                }}
              />
            </div>
            <div className="pr-2 max-md:pr-0">
              <TagPicker
                cleanable
                loading={isLoading}
                preventOverflow={false}
                style={{
                  width: tagPickerWidthSubType,
                  float: "right",
                }}
                menuStyle={{
                  width: tagPickerWidthSubType,
                  float: "right",
                  zIndex: 100,
                }}
                searchable={false}
                data={subtypesFilter.map((item) => {
                  return { label: item, value: item };
                })}
                placeholder="Tipo de Imóvel"
                onChange={(item) => {
                  console.log(item);
                  setHomeSubTypeList([item]);
                }}
              />
            </div>

            <div>
              <Link
                to={"/imovel" + (finalQuery === "" ? "" : `?&${finalQuery}`)}
                onClick={() => {
                  console.log(finalQuery);
                }}
              >
                <button
                  className={`${btnStyle.button2} w-fit px-8 py-2 bg-[#b23437] text-sm  max-md:px-24 max-md:rounded-xl  max-md:py-2`}
                >
                  Buscar
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* exclusividades */}
      <section className="pb-48 bg-white pt-14 max-md:py-14 max-md:mt-[-150px] max-md:overflow-hidden">
        {/* {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[18%] z-10 absolute mt-[6rem] left-0 touch-pan-y">
            {" "}
          </div>
        )}
        {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[50%] z-10 absolute mt-[17.5rem] left-0 touch-pan-y">
            {" "}
          </div>
        )}
        {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[18%] z-10 absolute mt-[6rem] right-0 touch-pan-y">
            {" "}
          </div>
        )}
        {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[50%] z-10 absolute mt-[17.5rem] right-0 touch-pan-y">
            {" "}
          </div>
        )} */}
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center pb-4 scale-125 max-md:scale-100">
            <h2 className="text-[#b23437] font-bold">
              Exclusividades Nossa Casa
            </h2>
            <span className="text-[#AEAEAE]">
              Imóveis que você só encontra na Nossa Casa
            </span>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center mt-[4rem] mb-20  ">
              <RiLoader2Line
                size={80}
                color="#b23437"
                className="animate-[spin_5s_linear_infinite]"
              />
            </div>
          ) : (
            <div className="grid-flow-col grid-cols-3 max-w-7xl max-md:max-w-full max-[950px]:max-w-[900px]">
              <Carousel
                swipeable={carouselYoutubeMobile.swipeable}
                showThumbs={false}
                showIndicators={false}
                emulateTouch={false}
                showArrows={carouselYoutubeMobile.arrows}
                showStatus={false}
                swipeScrollTolerance={45}
                preventMovementUntilSwipeScrollTolerance
                transitionTime={1000}
                centerMode={carouselYoutubeMobile.centerMode}
                centerSlidePercentage={carouselYoutubeMobile.percentage}
                selectedItem={carouselYoutubeMobile.initial}
                infiniteLoop={carouselYoutubeMobile.infinity}
                className="carousel-exclusivos"
                renderArrowNext={
                  carouselYoutubeMobile.arrows
                    ? (clickHandler) =>
                        arrowFoward({
                          onClickHandler: clickHandler,
                          size: 20,
                        })
                    : undefined
                }
                renderArrowPrev={
                  carouselYoutubeMobile.arrows
                    ? (clickHandler) =>
                        arrowPrev({
                          onClickHandler: clickHandler,
                          size: 20,
                        })
                    : undefined
                }
              >
                {infoAptosExclusive?.properties.map((info, i) => {
                  return (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      key={i}
                      className="scale-95 flex flex-col justify-between space-y-0 h-auto border-solid border-2 border-[#F5F5F5] max-w-[25.6rem] m-2 rounded-xl pb-6 min-h-[32rem] shadow-lg max-md:ml-0 max-md:mr-5 max-md:scale-100 max-md:max-w-lg max-md:min-h-[36rem]"
                    >
                      {/* {carouselYoutubeMobile.arrows ? null : (
                        <div className="bg-transparent w-[52.3%] h-[56%] z-50 fixed left-[6.3rem] touch-pan-x">
                          {" "}
                        </div>
                      )} */}

                      <Link
                        to={`/imovel/${info?.identifier_code}`}
                        className="bg-transparent w-[100%] h-[44%] z-40 fixed bottom-0"
                      >
                        {" "}
                      </Link>

                      <Carousel
                        infiniteLoop
                        swipeable={false}
                        transitionTime={850}
                        showStatus={false}
                        showThumbs={false}
                        className="carousel-exclusivos-inner"
                        renderArrowNext={(clickHandler) =>
                          arrowFoward({
                            onClickHandler: clickHandler,
                            size: 30,
                          })
                        }
                        renderArrowPrev={(clickHandler) =>
                          arrowPrev({
                            onClickHandler: clickHandler,
                            size: 30,
                          })
                        }
                      >
                        {info?.images.slice(0, 3).map((item, i) => {
                          return (
                            <>
                              <div
                                className="max-w-lg rounded-t-xl h-72 max-md:h-80"
                                onClick={(event) => event.preventDefault()}
                              >
                                {info?.has_exclusivity && i === 0 ? (
                                  <>
                                    <div>
                                      <button className="bg-[#DAA14A] text-sm pointer-events-none fixed top-4 right-[-8rem] w-32 rounded-r-xl text-white pr-4 py-2 text-right">
                                        Exclusividade
                                      </button>
                                    </div>
                                  </>
                                ) : null}
                                <img
                                  loading="lazy"
                                  src={item.thumbnail_url}
                                  className="object-cover rounded-t-xl h-72 max-md:h-80"
                                />
                              </div>
                            </>
                          );
                        })}
                      </Carousel>
                      <div className="mt-0">
                        <div className="bg-[#ececec] p-2 flex items-center justify-center">
                          <RiMapPin2Fill
                            color="#b23437"
                            className="mt-1 mb-2 mr-1"
                            size={17}
                          />
                          {info?.address_neighborhood} - {info?.address_city}
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <span className="px-2 pt-5 pb-1 text-xl font-bold">
                          {"R$" +
                            info?.sale_value.replace(
                              /(\d)(?=(\d{3})+(?!\d))/g,
                              "$1."
                            )}
                        </span>
                        {info.meta_title ? (
                          <h6 className="px-10 pt-2 pb-3 text-sm font-normal text-center">
                            {info?.meta_title.length >= 43
                              ? `${info?.meta_title.substring(0, 43)}...`
                              : info?.meta_title}
                          </h6>
                        ) : (
                          <br />
                        )}
                      </div>
                      <div className="flex justify-center gap-4 mx-5 text-xs font-medium max-md:mx-2 max-md:gap-4">
                        <span
                          className={`flex items-center justify-center gap-1 `}
                        >
                          <BiArea size={"22px"} />
                          {info?.private_area ? (
                            <>{info?.private_area} m²</>
                          ) : (
                            <>Consulte</>
                          )}
                        </span>

                        {/* {info.private_area ? (
                          <span
                            className={`flex items-center justify-center gap-1 ${
                              info?.private_area ? "flex" : "hidden"
                            }`}
                          >
                            <BiArea size={"22px"} />
                            {info?.private_area} m²
                          </span>
                        ) : (
                          <br />
                        )} */}
                        <span
                          className={`items-center justify-center gap-1 flex`}
                        >
                          <BiSolidBed size={"22px"} />

                          {info?.bedrooms === 0 ? (
                            <>Consulte</>
                          ) : (
                            <>{info?.bedrooms} Dormitórios</>
                          )}
                        </span>
                        <span
                          className={`items-center justify-center gap-1 flex`}
                        >
                          <BiSolidCarGarage size={"22px"} />
                          {info?.garages === 0 ? (
                            <>Consulte</>
                          ) : (
                            <>{info?.garages} Vagas</>
                          )}
                        </span>
                      </div>
                      <div className="z-50 flex items-center justify-center pt-4">
                        <Button
                          label="Saiba mais"
                          link={`/imovel/${info?.identifier_code}`}
                        />
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
      </section>
      {/* banner */}
      <section className="flex flex-col justify-center items-center pb-8 mt-10 bg-[#F1F1F1] max-md:pb-0">
        <a
          href="https://www.youtube.com/@nossacasaimoveistorresrs"
          target="_blank"
        >
          <img
            loading="lazy"
            src={banner}
            className="w-auto h-auto max-w-7xl  rounded-xl mt-[-216px] max-md:hidden max-[950px]:max-w-[900px]"
          />
          <img
            loading="lazy"
            src={bannerMob}
            className="w-auto h-auto rounded-xl mt-[-70px] max-w-md hidden max-md:block"
          />
        </a>
      </section>

      {/* imoveis com video */}
      <section className="pb-20 bg-[#F1F1F1] pt-6 max-md:py-10 max-md:overflow-hidden">
        {/* {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[18%] z-10 absolute mt-[7.5rem] left-0 touch-pan-y">
            {" "}
          </div>
        )}
        {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[45%] z-10 absolute mt-[19.2rem] left-0 touch-pan-y">
            {" "}
          </div>
        )}
        {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[18%] z-10 absolute mt-[7.5rem] right-0 touch-pan-y">
            {" "}
          </div>
        )}
        {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[45%] z-10 absolute mt-[19.2rem] right-0 touch-pan-y">
            {" "}
          </div>
        )} */}
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center pb-4 scale-125 max-md:scale-100">
            <h2 className="text-[#b23437] font-bold">Visita Virtual</h2>
            <span className="text-[#AEAEAE] text-center px-10">
              Explore nossos imóveis com visitas virtuais no nosso canal do
              YouTube
            </span>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center mt-[4rem] mb-20  ">
              <RiLoader2Line
                size={80}
                color="#b23437"
                className="animate-[spin_5s_linear_infinite]"
              />
            </div>
          ) : (
            <div className="grid-flow-col grid-cols-3 max-w-7xl max-md:max-w-full max-md:scale-95 max-[950px]:max-w-[900px]">
              <Carousel
                swipeable={carouselYoutubeMobile.swipeable}
                showThumbs={false}
                showIndicators={false}
                showArrows={carouselYoutubeMobile.arrows}
                showStatus={false}
                transitionTime={1000}
                swipeScrollTolerance={45}
                preventMovementUntilSwipeScrollTolerance
                centerMode={carouselYoutubeMobile.centerMode}
                centerSlidePercentage={carouselYoutubeMobile.percentage}
                selectedItem={carouselYoutubeMobile.initial}
                infiniteLoop={carouselYoutubeMobile.infinity}
                className="carousel-exclusivos"
                renderArrowNext={
                  carouselYoutubeMobile.arrows
                    ? (clickHandler) =>
                        arrowFoward({
                          onClickHandler: clickHandler,
                          size: 20,
                        })
                    : undefined
                }
                renderArrowPrev={
                  carouselYoutubeMobile.arrows
                    ? (clickHandler) =>
                        arrowPrev({
                          onClickHandler: clickHandler,
                          size: 20,
                        })
                    : undefined
                }
              >
                {infoAptosWithVideo?.properties.map((info, i) => {
                  return (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      key={i}
                      className="scale-95 bg-white flex flex-col justify-between space-y-0 h-auto border-solid border-2 border-[#F5F5F5] max-w-[25.6rem] m-2 rounded-xl pb-6 min-h-[32rem] shadow-lg max-md:ml-0 max-md:mr-5 max-md:scale-100 max-md:max-w-lg max-md:min-h-[34rem]"
                    >
                      <Link
                        to={`/imovel/${info?.identifier_code}`}
                        className="bg-transparent w-[100%] h-[44%] z-40 fixed bottom-0"
                      >
                        {" "}
                      </Link>
                      {/* {carouselYoutubeMobile.arrows ? null : (
                        <div className="bg-transparent w-[40%] h-[70%] z-50 fixed left-20 touch-pan-x">
                          {" "}
                        </div>
                      )} */}
                      <Carousel
                        infiniteLoop
                        swipeable={false}
                        transitionTime={850}
                        showStatus={false}
                        showThumbs={false}
                        className="carousel-exclusivos-inner"
                        renderArrowNext={(clickHandler) =>
                          arrowFoward({
                            onClickHandler: clickHandler,
                            size: 30,
                          })
                        }
                        renderArrowPrev={(clickHandler) =>
                          arrowPrev({
                            onClickHandler: clickHandler,
                            size: 30,
                          })
                        }
                      >
                        {info?.images.slice(0, 4).map((item, i) => {
                          return (
                            <>
                              <div
                                className="max-w-lg rounded-t-xl h-72 max-md:h-80"
                                onClick={(event) => event.preventDefault()}
                              >
                                {info?.has_exclusivity && i === 0 ? (
                                  <>
                                    <div className="">
                                      <button className="bg-[#DAA14A] text-xs pointer-events-none fixed top-4 right-[-7rem] w-28 rounded-r-xl text-white pr-4 py-2 text-right">
                                        Exclusividade
                                      </button>
                                    </div>
                                  </>
                                ) : null}
                                <img
                                  loading="lazy"
                                  src={item.thumbnail_url}
                                  className="rounded-t-xl h-72 max-md:h-80"
                                />
                              </div>
                            </>
                          );
                        })}
                      </Carousel>
                      <div className="mt-0">
                        <div className="bg-[#ececec] p-2 flex items-center justify-center">
                          <RiMapPin2Fill
                            color="#b23437"
                            className="mt-1 mb-2 mr-1"
                            size={17}
                          />
                          {info?.address_neighborhood} - {info?.address_city}
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <span className="px-2 pt-5 pb-1 text-xl font-bold">
                          {"R$" +
                            info?.sale_value.replace(
                              /(\d)(?=(\d{3})+(?!\d))/g,
                              "$1."
                            )}
                        </span>
                        {info.meta_title ? (
                          <h6 className="px-10 pt-2 pb-3 text-sm font-normal text-center">
                            {info?.meta_title.length >= 40
                              ? `${info?.meta_title.substring(0, 40)}...`
                              : info?.meta_title}
                          </h6>
                        ) : (
                          <br />
                        )}
                      </div>
                      <div className="flex justify-center gap-4 mx-5 text-xs font-medium max-md:mx-2 max-md:gap-4">
                        <span
                          className={`flex items-center justify-center gap-1 `}
                        >
                          <BiArea size={"22px"} />
                          {info?.private_area ? (
                            <>{info?.private_area} m²</>
                          ) : (
                            <>Consulte</>
                          )}
                        </span>

                        {/* {info.private_area ? (
                          <span
                            className={`flex items-center justify-center gap-1 ${
                              info?.private_area ? "flex" : "hidden"
                            }`}
                          >
                            <BiArea size={"22px"} />
                            {info?.private_area} m²
                          </span>
                        ) : (
                          <br />
                        )} */}
                        <span
                          className={`items-center justify-center gap-1 flex`}
                        >
                          <BiSolidBed size={"22px"} />

                          {info?.bedrooms === 0 ? (
                            <>Consulte</>
                          ) : (
                            <>{info?.bedrooms} Dormitórios</>
                          )}
                        </span>
                        <span
                          className={`items-center justify-center gap-1 flex`}
                        >
                          <BiSolidCarGarage size={"22px"} />
                          {info?.garages === 0 ? (
                            <>Consulte</>
                          ) : (
                            <>{info?.garages} Vagas</>
                          )}
                        </span>
                      </div>
                      <div className="flex items-center justify-center pt-4">
                        <Button
                          label="Saiba mais"
                          link={`/imovel/${info?.identifier_code}`}
                        />
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
      </section>

      {/* imoveis imobiliados */}
      <section className="bg-black pt-14 pb-14 image-sea bg-opacity-5 max-md:py-10 max-md:overflow-hidden">
        {/* {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[18%] z-10 absolute mt-[6.1rem] left-0 touch-pan-y">
            {" "}
          </div>
        )}
        {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[45%] z-10 absolute mt-[18rem] left-0 touch-pan-y">
            {" "}
          </div>
        )}
        {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[18%] z-10 absolute mt-[6.1rem] right-0 touch-pan-y">
            {" "}
          </div>
        )}
        {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[45%] z-10 absolute mt-[18rem] right-0 touch-pan-y">
            {" "}
          </div>
        )} */}
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center pb-4 scale-125 max-md:scale-100">
            <h2 className="font-bold text-white">
              Encontre seu imóvel mobiliado em Torres
            </h2>
            <span className="px-10 text-center text-white">
              Imóveis mobiliados em Torres
            </span>
          </div>
          {isLoading ? (
            <div className="flex items-center justify-center mt-[4rem] mb-20  ">
              <RiLoader2Line
                size={80}
                color="#b23437"
                className="animate-[spin_5s_linear_infinite]"
              />
            </div>
          ) : (
            <div className="grid-flow-col grid-cols-3 max-w-7xl max-md:max-w-full max-md:scale-95 max-[950px]:max-w-[900px]">
              <Carousel
                swipeable={carouselYoutubeMobile.swipeable}
                showThumbs={false}
                showIndicators={false}
                showArrows={carouselYoutubeMobile.arrows}
                showStatus={false}
                swipeScrollTolerance={45}
                preventMovementUntilSwipeScrollTolerance
                transitionTime={1000}
                centerMode={carouselYoutubeMobile.centerMode}
                centerSlidePercentage={carouselYoutubeMobile.percentage}
                selectedItem={carouselYoutubeMobile.initial}
                infiniteLoop={carouselYoutubeMobile.infinity}
                className="carousel-exclusivos"
                renderArrowNext={
                  carouselYoutubeMobile.arrows
                    ? (clickHandler) =>
                        arrowFoward({
                          onClickHandler: clickHandler,
                          size: 20,
                        })
                    : undefined
                }
                renderArrowPrev={
                  carouselYoutubeMobile.arrows
                    ? (clickHandler) =>
                        arrowPrev({
                          onClickHandler: clickHandler,
                          size: 20,
                        })
                    : undefined
                }
              >
                {infoAptosFurnished?.properties.map((info, i) => {
                  return (
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      key={i}
                      className="bg-white scale-95 flex flex-col justify-between space-y-0 h-auto  max-w-[25.6rem] m-2 rounded-xl pb-6 min-h-[32rem] shadow-lg max-md:ml-0 max-md:mr-5 max-md:scale-100 max-md:max-w-lg max-md:min-h-[34rem]"
                    >
                      {/* {carouselYoutubeMobile.arrows ? null : (
                        <div className="bg-transparent w-[40%] h-[70%] z-50 fixed left-20 touch-pan-x">
                          {" "}
                        </div>
                      )} */}
                      <Link
                        to={`/imovel/${info?.identifier_code}`}
                        className="bg-transparent w-[100%] h-[44%] z-40 fixed bottom-0"
                      >
                        {" "}
                      </Link>
                      <Carousel
                        infiniteLoop
                        swipeable={false}
                        transitionTime={850}
                        showStatus={false}
                        showThumbs={false}
                        className="carousel-exclusivos-inner"
                        renderArrowNext={(clickHandler) =>
                          arrowFoward({
                            onClickHandler: clickHandler,
                            size: 30,
                          })
                        }
                        renderArrowPrev={(clickHandler) =>
                          arrowPrev({
                            onClickHandler: clickHandler,
                            size: 30,
                          })
                        }
                      >
                        {info?.images.slice(0, 4).map((item, i) => {
                          return (
                            <>
                              <div
                                className="max-w-lg rounded-t-xl h-72 max-md:h-80"
                                onClick={(event) => event.preventDefault()}
                              >
                                {info?.has_exclusivity && i === 0 ? (
                                  <>
                                    <div className="">
                                      <button className="bg-[#DAA14A] text-xs pointer-events-none fixed top-4 right-[-7rem] w-28 rounded-r-xl text-white pr-4 py-2 text-right">
                                        Exclusividade
                                      </button>
                                    </div>
                                  </>
                                ) : null}
                                <img
                                  loading="lazy"
                                  src={item.thumbnail_url}
                                  className="rounded-t-xl h-72 max-md:h-80"
                                />
                              </div>
                            </>
                          );
                        })}
                      </Carousel>
                      <div className="mt-0">
                        <div className="bg-[#ececec] p-2 flex items-center justify-center">
                          <RiMapPin2Fill
                            color="#b23437"
                            className="mt-1 mb-2 mr-1"
                            size={17}
                          />
                          {info?.address_neighborhood} - {info?.address_city}
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center">
                        <span className="px-2 pt-5 pb-1 text-xl font-bold">
                          {"R$" +
                            info?.sale_value.replace(
                              /(\d)(?=(\d{3})+(?!\d))/g,
                              "$1."
                            )}
                        </span>
                        {info.meta_title ? (
                          <h6 className="px-10 pt-2 pb-3 text-sm font-normal text-center">
                            {info?.meta_title.length >= 41
                              ? `${info?.meta_title.substring(0, 41)}...`
                              : info?.meta_title}
                          </h6>
                        ) : (
                          <br />
                        )}
                      </div>
                      <div className="flex justify-center gap-4 mx-5 text-xs font-medium max-md:mx-2 max-md:gap-4">
                        <span
                          className={`flex items-center justify-center gap-1 `}
                        >
                          <BiArea size={"22px"} />
                          {info?.private_area ? (
                            <>{info?.private_area} m²</>
                          ) : (
                            <>Consulte</>
                          )}
                        </span>

                        {/* {info.private_area ? (
                          <span
                            className={`flex items-center justify-center gap-1 ${
                              info?.private_area ? "flex" : "hidden"
                            }`}
                          >
                            <BiArea size={"22px"} />
                            {info?.private_area} m²
                          </span>
                        ) : (
                          <br />
                        )} */}
                        <span
                          className={`items-center justify-center gap-1 flex`}
                        >
                          <BiSolidBed size={"22px"} />

                          {info?.bedrooms === 0 ? (
                            <>Consulte</>
                          ) : (
                            <>{info?.bedrooms} Dormitórios</>
                          )}
                        </span>
                        <span
                          className={`items-center justify-center gap-1 flex`}
                        >
                          <BiSolidCarGarage size={"22px"} />
                          {info?.garages === 0 ? (
                            <>Consulte</>
                          ) : (
                            <>{info?.garages} Vagas</>
                          )}
                        </span>
                      </div>
                      <div className="flex items-center justify-center pt-4">
                        <Button
                          label="Saiba mais"
                          link={`/imovel/${info?.identifier_code}`}
                        />
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
      </section>

      {/* blog */}
      <section className="bg-white pt-14 pb-14 max-md:py-10 max-md:overflow-hidden">
        {/* {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[70%] z-10 absolute mt-[7rem] left-0 touch-pan-y">
            {" "}
          </div>
        )}
        {carouselYoutubeMobile.arrows ? null : (
          <div className="bg-transparent w-[10%] h-[70%] z-10 absolute mt-[7rem] right-0 touch-pan-y">
            {" "}
          </div>
        )} */}

        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col items-center justify-center pb-4 scale-125 max-md:scale-100">
            <h2 className="text-[#b23437] font-bold">Blog Nossa Casa</h2>
            <span className="text-[#AEAEAE] text-center px-10">
              Saiba tudo sobre Torres/RS diretamente de quem entende
            </span>
          </div>
          {blogLoading ? (
            <div className="flex items-center justify-center mt-[4rem] pb-20">
              <RiLoader2Line
                size={80}
                color="#b23437"
                className="animate-[spin_5s_linear_infinite]"
              />
            </div>
          ) : (
            <div className="grid-flow-col grid-cols-3 max-w-7xl max-md:max-w-full max-md:scale-95 max-[950px]:max-w-[900px]">
              <Carousel
                swipeable={carouselYoutubeMobile.swipeable}
                showThumbs={false}
                showIndicators={false}
                showArrows={carouselYoutubeMobile.arrows}
                showStatus={false}
                transitionTime={1000}
                centerMode={carouselYoutubeMobile.centerMode}
                centerSlidePercentage={carouselYoutubeMobile.percentage}
                selectedItem={carouselYoutubeMobile.initialPost}
                infiniteLoop={carouselYoutubeMobile.infinity}
                swipeScrollTolerance={45}
                preventMovementUntilSwipeScrollTolerance
                className="carousel-exclusivos"
                renderArrowNext={
                  carouselYoutubeMobile.arrows
                    ? (clickHandler) =>
                        arrowFoward({
                          onClickHandler: clickHandler,
                          size: 20,
                        })
                    : undefined
                }
                renderArrowPrev={
                  carouselYoutubeMobile.arrows
                    ? (clickHandler) =>
                        arrowPrev({
                          onClickHandler: clickHandler,
                          size: 20,
                        })
                    : undefined
                }
              >
                {listPosts?.map((item) => {
                  const imgURL =
                    item?.content.rendered.match(/\bhttps?:\/\/\S+/gi);

                  const finalImgUrl = imgURL?.find((item) =>
                    item.includes(
                      "https://www.nossacasa.com.br/blog/wp-content/uploads/"
                    )
                  );

                  const blogImg =
                    finalImgUrl !== undefined
                      ? finalImgUrl.replace(
                          "www.nossacasa.com.br/blog",
                          "blog.nossacasa.com.br"
                        )
                      : "https://www.nossacasa.com.br/blog/wp-content/uploads/2023/08/IMG_1768-1024x683.jpg";

                  // console.log(blogImg);

                  return (
                    <div
                      className="scale-95 flex flex-col justify-between space-y-0 h-auto border-solid border-2 border-[#F5F5F5] max-w-[25.6rem] m-2 rounded-xl pb-10 min-h-[32rem] shadow-md max-md:ml-0 max-md:mr-5 max-md:scale-100 max-md:max-w-lg max-md:min-h-[36rem]"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      {/* {carouselYoutubeMobile.arrows ? null : (
                        <div className="bg-transparent w-[40%] h-[70%] z-50 fixed left- touch-pan-x">
                          {" "}
                        </div>
                      )} */}
                      <Link
                        to={item?.link}
                        className="bg-transparent w-[100%] h-[44%] z-40 fixed bottom-0"
                        target="_blank"
                      >
                        {" "}
                      </Link>
                      <div className="max-w-lg rounded-t-xl h-72 max-md:h-80 justify-evenly">
                        <img
                          src={blogImg}
                          className="rounded-t-xl h-72 max-md:h-80"
                          loading="lazy"
                        />
                        <div className="flex flex-col justify-between gap-2">
                          <h6 className="pt-4 font-light">Artigo no Blog</h6>
                          <h6 className="px-4 py-2 mx-4 mb-4 text-lg font-bold text-center">
                            {item?.title.rendered}
                          </h6>
                          <a
                            href={item?.link}
                            target="_blank"
                            className="text-white"
                          >
                            <button className={btnStyle.button2}>
                              Leia Mais
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          )}
        </div>
      </section>

      <CookieConsent
        buttonText="PROSSEGUIR"
        style={{
          background: "white",
          borderTop: "5px solid #b23437",
          color: "#585858",
        }}
        buttonStyle={{
          backgroundColor: "#b23437",
          color: "white",
          marginRight: carouselYoutubeMobile.arrows ? "120px" : "inherit",
          borderRadius: "4px",
        }}
      >
        Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua
        experiência. Ao utilizar nossos serviços, você concorda com isso.
      </CookieConsent>

      {/* card corretores */}
      {/* <section className="my-20">
        <div className="flex flex-col items-center justify-center pb-4">
          <h3 className="font-extrabold text-[#b23437]">
            Depoimentos de Clientes
          </h3>
          <h6 className="font-thin text-[#AEAEAE]">
            Viva a experiência de comprar aqui
          </h6>
        </div>

        <div className="flex flex-row items-center justify-center pt-[150px] gap-4">
          <div className="flex flex-col items-center justify-center max-w-sm p-8 border-2 shadow-md rounded-2xl">
            <img
              src="https://loremflickr.com/1024/1024?random=22"
              className="max-w-[12rem] rounded-full mt-[-150px] mb-6 shadow-md"
            />
            <p className="mr-[-10px] pb-4 text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h5>Fulano de tal</h5>
            <h6 className="font-thin">Empresário</h6>
          </div>

          <div className="flex flex-col items-center justify-center max-w-sm p-8 border-2 shadow-md rounded-2xl">
            <img
              src="https://loremflickr.com/1024/1024?random=34"
              className="max-w-[12rem] rounded-full mt-[-150px] mb-6 shadow-md"
            />
            <p className="mr-[-10px] pb-4 text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h5>Fulano de tal</h5>
            <h6 className="font-thin">Empresário</h6>
          </div>

          <div className="flex flex-col items-center justify-center max-w-sm p-8 border-2 shadow-md rounded-2xl">
            <img
              src="https://loremflickr.com/1024/1024?random=54"
              className="max-w-[12rem] rounded-full mt-[-150px] mb-6 shadow-md"
            />
            <p className="mr-[-10px] pb-4 text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h5>Fulano de tal</h5>
            <h6 className="font-thin">Empresário</h6>
          </div>
        </div>
      </section> */}
    </>
  );
}
