import { useEffect, useState } from "react";
import { api } from "../api";
import { Button } from "../components/Button";
import { ListAptos } from "../aptos.type";
import { FilterBar, findOrderBy, removeItem } from "../components/FilterBar";
import { BiArea, BiSolidBed, BiSolidCarGarage } from "react-icons/bi";
import { RiLoader2Line, RiMapPin2Fill } from "react-icons/ri";
import { AiFillCloseCircle } from "react-icons/ai";
import { Carousel } from "react-responsive-carousel";
import { Filter } from "../components/Filter";
import React from "react";
import QueryString from "qs";
import { useLocation, useSearchParams } from "react-router-dom";
import { findNeighborhood } from "./Propertie";
import { arrowFowardWhite, arrowPrevWhite } from "../components/Arrows";
import { Pagination } from "rsuite";

let listAptos: ListAptos;

// const urlPage = window.location.search;
// console.log(urlPage);

export interface Cities {
  city: string;
  city_id: number;
}

let initialInfoCities: Cities[];

export interface Neighborhoods {
  address_neighborhood: AddressNeighborhood[];
}

interface AddressNeighborhood {
  neighborhood: string;
  neighborhood_id: number;
}

let initialInfoNeighborhoodList: Neighborhoods;

export function Buy() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  //variavel para receber do server
  const [infoCities, setInfoCities] = useState(initialInfoCities);

  const [infoNeighborhoodList, setInfoNeighborhoodList] =
    useState<Neighborhoods>(initialInfoNeighborhoodList);

  function findCity(thisValue: number) {
    const labelOfValue =
      infoCities === undefined
        ? undefined
        : infoCities.find((value) => value.city_id === thisValue);
    return labelOfValue?.city;
  }

  //cidade selecionada
  // const [citiesList, setCitiesList] = useState<String[]>([""]);

  //city
  const cityParams = searchParams.getAll("city");

  const initalCity =
    cityParams.length === 0 ? ["CITY"] : ["CITY", ...cityParams];

  const [cityList, setCityList] = useState(initalCity);

  //neighborhood
  const neighborhoodParams = searchParams.getAll("neighborhood");

  const initialNeighboorhod =
    neighborhoodParams.length === 0
      ? ["NEIGHBORHOOD"]
      : ["NEIGHBORHOOD", ...neighborhoodParams];

  const [neighborhoodList, setNeighborhoodList] = useState(initialNeighboorhod);

  //dormitórios
  const bedroomsParams = searchParams.getAll("bedrooms");

  const initialBedrooms =
    neighborhoodParams.length === 0
      ? ["BEDROOMS"]
      : ["BEDROOMS", ...bedroomsParams];

  const [bedroomsList, setBedroomsList] = useState(initialBedrooms);

  //banheiros
  const bathsParams = searchParams.getAll("baths");

  const initialBathrooms =
    bathsParams.length === 0 ? ["BATHROOMS"] : ["BATHROOMS", ...bathsParams];

  const [bathroomsList, setBathroomsList] = useState(initialBathrooms);

  //garagem
  const garageParams = searchParams.getAll("garages");

  const initialGarage =
    bathsParams.length === 0 ? ["GARAGES"] : ["GARAGES", ...garageParams];

  const [garagesList, setGaragesList] = useState(initialGarage);

  //subtype
  const subTypeParams = searchParams.getAll("subtype");

  const initialSubType =
    subTypeParams.length === 0 ? ["SUBTYPE"] : ["SUBTYPE", ...subTypeParams];

  const [subTypeList, setSubTypeList] = useState(initialSubType);

  //status
  const statusParams = searchParams.getAll("status");

  const initialStatus =
    statusParams.length === 0 ? ["STATUS"] : ["STATUS", ...statusParams];

  const [statusList, setStatusList] = useState(initialStatus);

  //search
  const initialSearch = searchParams.get("search")
    ? searchParams.get("search")
    : "";

  let [searchText, setSearchText] = useState(initialSearch);

  //Video Filter
  const initialVideoFilter = searchParams.get("withVideo")
    ? Boolean(searchParams.get("withVideo"))
    : false;

  const [videoFilter, setVideoFilter] = useState(initialVideoFilter);

  //Exclusive Filter
  const initialExclusiveFilter = searchParams.get("exclusive")
    ? Boolean(searchParams.get("exclusive"))
    : false;

  const [exclusiveFilter, setExclusiveFilter] = useState(
    initialExclusiveFilter
  );

  //minPrice
  const initialMinPrice = searchParams.get("minPrice")
    ? ["minPrice", searchParams.get("minPrice")]
    : ["minPrice"];

  const [minPriceList, setMinPriceList] = useState(initialMinPrice);

  //maxPrice
  const initialMaxPrice = searchParams.get("maxPrice")
    ? ["maxPrice", searchParams.get("maxPrice")]
    : ["maxPrice"];

  const [maxPriceList, setMaxPriceList] = useState(initialMaxPrice);

  //Area Minima
  const initialMinArea = searchParams.get("minArea")
    ? ["minArea", searchParams.get("minArea")]
    : ["minArea"];

  const [minAreaList, setMinAreaList] = useState(initialMinArea);

  //Area maxima
  const initialMaxArea = searchParams.get("maxArea")
    ? ["maxArea", searchParams.get("maxArea")]
    : ["maxArea"];

  const [maxAreaList, setMaxAreaList] = useState(initialMaxArea);

  const [reload, setReload] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const [infoAptos, setInfoAptos] = useState(listAptos);

  //Page
  const initalPage = searchParams.get("page")
    ? Number(searchParams.get("page"))
    : 1;

  const [currentPage, setCurrentPage] = useState(initalPage);

  //OrderBy
  const initialOrderBy = searchParams.get("OrderBy")
    ? Number(searchParams.get("OrderBy"))
    : 0;

  const [orderBy, setOrderBy] = useState(initialOrderBy);

  //ARRAYS TO QUERY
  const query = QueryString;

  const finalCityQuery = query.stringify(
    { city: cityList.slice(1) },
    { indices: false }
  );

  const finalNeighborhoodQuery = query.stringify(
    { neighborhood: neighborhoodList.slice(1) },
    { indices: false }
  );

  const finalSubtypeQuery = query.stringify(
    { subtype: subTypeList.slice(1) },
    { indices: false }
  );

  const finalStatusQuery = query.stringify(
    { status: statusList.slice(1) },
    { indices: false }
  );

  const finalSearchQuery = query.stringify(
    { search: searchText === "" ? undefined : searchText },
    { indices: false }
  );

  const finalBedroomsQuery = query.stringify(
    { bedrooms: bedroomsList[1] === "" ? undefined : bedroomsList.slice(1) },
    { indices: false }
  );

  const finalBathroomsQuery = query.stringify(
    { baths: bathroomsList[1] === "" ? undefined : bathroomsList.slice(1) },
    { indices: false }
  );

  const finalGaragesQuery = query.stringify(
    {
      garages: garagesList[1] === "" ? undefined : garagesList.slice(1),
    },
    { indices: false }
  );

  const finalVideoQuery = query.stringify(
    {
      withVideo: videoFilter === false ? undefined : videoFilter,
    },
    { indices: false }
  );

  const finalExclusiveQuery = query.stringify(
    { exclusive: exclusiveFilter === false ? undefined : exclusiveFilter },
    { indices: false }
  );

  const finalMinPriceQuery = query.stringify(
    {
      minPrice: minPriceList[1] === "" ? undefined : minPriceList.slice(1),
    },
    { indices: false }
  );

  const finalMaxPriceQuery = query.stringify(
    {
      maxPrice: maxPriceList[1] === "" ? undefined : maxPriceList.slice(1),
    },
    { indices: false }
  );

  const finalMinAreaQuery = query.stringify(
    { minArea: minAreaList[1] === "" ? undefined : minAreaList.slice(1) },
    { indices: false }
  );

  const finalMaxAreaQuery = query.stringify(
    {
      maxArea: maxAreaList[1] === "" ? undefined : maxAreaList.slice(1),
    },
    {
      indices: false,
    }
  );

  const finalPageQuery = query.stringify(
    { page: currentPage === 1 ? undefined : currentPage },
    { indices: false }
  );

  const finalOrderByQuery = query.stringify(
    {
      orderBy: orderBy === 0 ? undefined : orderBy,
    },
    { indices: false }
  );

  async function getAptos() {
    setIsLoading(true);

    const city = finalCityQuery ? `&${finalCityQuery}` : "";
    const neighborhood = finalNeighborhoodQuery
      ? `&${finalNeighborhoodQuery}`
      : "";
    const subType = finalSubtypeQuery ? `&${finalSubtypeQuery}` : "";
    const status = finalStatusQuery ? `&${finalStatusQuery}` : "";
    const search = finalSearchQuery ? `&${finalSearchQuery}` : "";
    const bedrooms = finalBedroomsQuery ? `&${finalBedroomsQuery}` : "";
    const bathrooms = finalBathroomsQuery ? `&${finalBathroomsQuery}` : "";
    const garages = finalGaragesQuery ? `&${finalGaragesQuery}` : "";
    const withVideo = finalVideoQuery ? `&${finalVideoQuery}` : "";
    const exclusive = finalExclusiveQuery ? `&${finalExclusiveQuery}` : "";
    const minPrice = finalMinPriceQuery ? `&${finalMinPriceQuery}` : "";
    const maxPrice = finalMaxPriceQuery ? `&${finalMaxPriceQuery}` : "";
    const minArea = finalMinAreaQuery ? `&${finalMinAreaQuery}` : "";
    const maxArea = finalMaxAreaQuery ? `&${finalMaxAreaQuery}` : "";
    const page = finalPageQuery ? `&${finalPageQuery}` : "";
    const orderBy = finalOrderByQuery ? `&${finalOrderByQuery}` : "";

    const finalQuery =
      page +
      orderBy +
      city +
      neighborhood +
      subType +
      status +
      bedrooms +
      bathrooms +
      garages +
      withVideo +
      exclusive +
      minPrice +
      maxPrice +
      minArea +
      maxArea +
      search;

    setSearchParams(finalQuery);
    const replaceQuery = finalQuery === "" ? "/imovel" : "/imovel?";
    window.history.replaceState(null, "", replaceQuery + finalQuery);

    const data = await api.get(`/properties?` + finalQuery);

    return setInfoAptos(data.data);
  }

  async function getInfoData() {
    const data = await api.get("/info");
    return setInfoCities(data.data);
  }

  async function getInfoNeighboorhod() {
    if (finalCityQuery !== "") {
      await api.get(`/info?${finalCityQuery}`).then((data) => {
        setInfoNeighborhoodList(data.data);
      });
    }
  }

  useEffect(() => {
    getInfoData().then(() => {
      getInfoNeighboorhod();
    });
    getAptos().then(() => {
      setIsLoading(false);
    });
    return;
  }, [
    finalCityQuery,
    finalNeighborhoodQuery,
    finalSubtypeQuery,
    finalStatusQuery,
    finalBedroomsQuery,
    finalBathroomsQuery,
    finalGaragesQuery,
    finalVideoQuery,
    finalExclusiveQuery,
    finalMinPriceQuery,
    finalMaxPriceQuery,
    finalMinAreaQuery,
    finalMaxAreaQuery,
    finalPageQuery,
    reload,
  ]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [finalPageQuery, location]);

  // console.log(infoNeighborhoodList);

  return (
    <>
      <div className="flex flex-col items-center justify-center p-7 pt-10 pb-9 mt-[6rem] bg-gradient-to-b from-[#F9F9F9] from-95% to-transparent max-md:bg-[#EEEEEE] max-md:pl-0 max-md:pt-8 max-md:pb-1 max-md:mt-[7rem]">
        <FilterBar
          modalIsOpen={open}
          setOpen={setOpen}
          setSearchText={setSearchText}
          setReload={setReload}
          setNeighborhoodList={setNeighborhoodList}
          setStatusList={setStatusList}
          setSubTypeList={setSubTypeList}
          setOrderBy={setOrderBy}
          setCity={setCityList}
          setIsLoading={setIsLoading}
          setinfoNeighborhood={setInfoNeighborhoodList}
          infoNeighborhoodsList={infoNeighborhoodList}
          cities={cityList}
          orderBy={orderBy}
          statusList={statusList}
          subTypeList={subTypeList}
          searchText={searchText}
          neighborhoodList={neighborhoodList}
          reload={reload}
          isLoading={isLoading}
          infoCities={infoCities}
        />
      </div>

      <div className="flex items-center justify-center mt-4 mx-[14%] max-md:inline-block max-md:align-top max-md:content-center max-md:m-auto max-md:pl-8 max-md:pt-5 active-filters">
        {finalCityQuery === ""
          ? null
          : cityList.slice(1).map((item) => {
              return (
                <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block max-md:sticky z-30">
                  <span className="px-1 cursor-default">
                    {findCity(Number(item))}
                  </span>
                  <button
                    onClick={() => {
                      removeItem(cityList, cityList[1]);
                      // setCityList(["CITY"]);
                      setInfoNeighborhoodList(initialInfoNeighborhoodList);
                      reload ? setReload(false) : setReload(true);
                      window.history.replaceState(null, "/imovel");
                      window.location.replace("/imovel");
                    }}
                  >
                    <AiFillCloseCircle className="pb-1" size={20} />
                  </button>
                </div>
              );
            })}
        {infoNeighborhoodList === undefined
          ? null
          : neighborhoodList.slice(1).map((item) => {
              console.log({ infoNeighborhoodList: infoNeighborhoodList });

              console.log({ item: item });

              console.log({ teste: typeof infoNeighborhoodList === undefined });

              console.log({
                ternário: console.log(
                  infoNeighborhoodList?.address_neighborhood?.find(
                    (address) => address.neighborhood_id === Number(item)
                  )?.neighborhood
                ),
              });
              return (
                <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block max-md:sticky z-30">
                  <span className="px-1 cursor-default">
                    {
                      infoNeighborhoodList?.address_neighborhood?.find(
                        (address) => address.neighborhood_id === Number(item)
                      )?.neighborhood
                    }
                  </span>
                  <button
                    onClick={() => {
                      reload ? setReload(false) : setReload(true);

                      neighborhoodList.includes(`${item}`)
                        ? removeItem(neighborhoodList, `${item}`)
                        : setNeighborhoodList([...neighborhoodList, `${item}`]);
                    }}
                  >
                    <AiFillCloseCircle className="pb-1" size={20} />
                  </button>
                </div>
              );
            })}
        {finalSubtypeQuery === ""
          ? null
          : subTypeList.slice(1).map((item) => {
              return (
                <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
                  <span className="px-1">{item}</span>
                  <button
                    onClick={() => {
                      reload ? setReload(false) : setReload(true);
                      subTypeList.includes(`${item}`)
                        ? removeItem(subTypeList, `${item}`)
                        : setSubTypeList([...subTypeList, `${item}`]);
                    }}
                  >
                    <AiFillCloseCircle className="pb-1" size={20} />
                  </button>
                </div>
              );
            })}
        {finalStatusQuery === ""
          ? null
          : statusList.slice(1).map((item) => {
              return (
                <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
                  <span className="px-1">{item}</span>
                  <button
                    onClick={() => {
                      reload ? setReload(false) : setReload(true);
                      statusList.includes(`${item}`)
                        ? removeItem(statusList, `${item}`)
                        : setStatusList([...statusList, `${item}`]);
                    }}
                  >
                    <AiFillCloseCircle className="pb-1" size={20} />
                  </button>
                </div>
              );
            })}
        {initialSearch === "" ? null : (
          <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
            <span className="px-1">
              Busca:{" "}
              <b>
                <i>{initialSearch}</i>
              </b>
            </span>
            <button
              onClick={() => {
                reload ? setReload(false) : setReload(true);
                setSearchText("");
              }}
            >
              <AiFillCloseCircle className="pb-1" size={20} />
            </button>
          </div>
        )}
        {finalBedroomsQuery === ""
          ? null
          : bedroomsList.slice(1).map((item) => {
              return (
                <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
                  <span className="px-1">
                    {item}
                    {item === "5" ? "+" : null}{" "}
                    {item === "1" ? "Dormitório" : "Dormitórios"}
                  </span>
                  <button
                    onClick={() => {
                      reload ? setReload(false) : setReload(true);
                      bedroomsList.includes(`${item}`)
                        ? removeItem(bedroomsList, `${item}`)
                        : setBedroomsList([...bedroomsList, `${item}`]);
                    }}
                  >
                    <AiFillCloseCircle className="pb-1" size={20} />
                  </button>
                </div>
              );
            })}

        {finalBathroomsQuery === ""
          ? null
          : bathroomsList.slice(1).map((item) => {
              return (
                <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
                  <span className="px-1">
                    {item}
                    {item === "5" ? "+" : null}{" "}
                    {item === "1" ? "Banheiro" : "Banheiros"}
                  </span>
                  <button
                    onClick={() => {
                      reload ? setReload(false) : setReload(true);
                      bathroomsList.includes(`${item}`)
                        ? removeItem(bathroomsList, `${item}`)
                        : setBathroomsList([...bathroomsList, `${item}`]);
                    }}
                  >
                    <AiFillCloseCircle className="pb-1" size={20} />
                  </button>
                </div>
              );
            })}

        {finalGaragesQuery === ""
          ? null
          : garagesList.slice(1).map((item) => {
              return (
                <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
                  <span className="px-1">
                    {item}
                    {item === "5" ? "+" : null}{" "}
                    {item === "1" ? "Vaga" : "Vagas"}
                  </span>
                  <button
                    onClick={() => {
                      reload ? setReload(false) : setReload(true);
                      garagesList.includes(`${item}`)
                        ? removeItem(garagesList, `${item}`)
                        : setGaragesList([...garagesList, `${item}`]);
                    }}
                  >
                    <AiFillCloseCircle className="pb-1" size={20} />
                  </button>
                </div>
              );
            })}

        {finalMinPriceQuery === "" ? null : (
          <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
            <span className="px-1">
              Valor Mínimo:{" "}
              <i>
                R$
                {minPriceList[1]?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
              </i>
            </span>
            <button
              onClick={() => {
                reload ? setReload(false) : setReload(true);
                minPriceList.includes(`${minPriceList[1]}`)
                  ? removeItem(minPriceList, `${minPriceList[1]}`)
                  : setMinPriceList([...minPriceList, `${minPriceList[1]}`]);
              }}
            >
              <AiFillCloseCircle className="pb-1" size={20} />
            </button>
          </div>
        )}
        {finalMaxPriceQuery === "" ? null : (
          <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
            <span className="px-1">
              Valor Máximo:{" "}
              <i>
                R$
                {maxPriceList[1]?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
              </i>
            </span>
            <button
              onClick={() => {
                reload ? setReload(false) : setReload(true);
                maxPriceList.includes(`${maxPriceList[1]}`)
                  ? removeItem(maxPriceList, `${maxPriceList[1]}`)
                  : setMaxPriceList([...maxPriceList, `${maxPriceList[1]}`]);
              }}
            >
              <AiFillCloseCircle className="pb-1" size={20} />
            </button>
          </div>
        )}
        {finalMinAreaQuery === "" ? null : (
          <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
            <span className="px-1">
              Área Mínima: <i>{minAreaList[1]}m²</i>
            </span>
            <button
              onClick={() => {
                reload ? setReload(false) : setReload(true);
                minAreaList.includes(`${minAreaList[1]}`)
                  ? removeItem(minAreaList, `${minAreaList[1]}`)
                  : setMinAreaList([...minAreaList, `${minAreaList[1]}`]);
              }}
            >
              <AiFillCloseCircle className="pb-1" size={20} />
            </button>
          </div>
        )}
        {finalMaxAreaQuery === "" ? null : (
          <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
            <span className="px-1">
              Área Máxima: <i>{maxAreaList[1]}m²</i>
            </span>
            <button
              onClick={() => {
                reload ? setReload(false) : setReload(true);
                maxAreaList.includes(`${maxAreaList[1]}`)
                  ? removeItem(maxAreaList, `${maxAreaList[1]}`)
                  : setMaxAreaList([...maxAreaList, `${maxAreaList[1]}`]);
              }}
            >
              <AiFillCloseCircle className="pb-1" size={20} />
            </button>
          </div>
        )}

        {videoFilter ? (
          <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
            <span className="px-1">Com vídeo</span>
            <button
              onClick={() => {
                reload ? setReload(false) : setReload(true);
                videoFilter ? setVideoFilter(false) : setVideoFilter(true);
              }}
            >
              <AiFillCloseCircle className="pb-1" size={20} />
            </button>
          </div>
        ) : null}
        {exclusiveFilter ? (
          <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
            <span className="px-1">Exclusividades</span>
            <button
              onClick={() => {
                reload ? setReload(false) : setReload(true);
                exclusiveFilter
                  ? setExclusiveFilter(false)
                  : setExclusiveFilter(true);
              }}
            >
              <AiFillCloseCircle className="pb-1" size={20} />
            </button>
          </div>
        ) : null}

        {orderBy !== 0 ? (
          <div className="flex items-center justify-between px-2 py-2 min-w-fit bg-[#F9F9F9] rounded-2xl mx-1 my-2 shadow-md max-md:m-2 max-md:inline-block private_area z-30">
            <span className="px-1">
              Ordenar: <i>{findOrderBy(orderBy)}</i>
            </span>
            <button
              onClick={() => {
                setOrderBy(0);
                reload ? setReload(false) : setReload(true);
              }}
            >
              <AiFillCloseCircle className="pb-1" size={20} />
            </button>
          </div>
        ) : null}

        {finalCityQuery +
          finalNeighborhoodQuery +
          finalOrderByQuery +
          finalSubtypeQuery +
          finalStatusQuery +
          finalBedroomsQuery +
          finalBathroomsQuery +
          finalGaragesQuery +
          finalVideoQuery +
          finalExclusiveQuery +
          finalMinPriceQuery +
          finalMaxPriceQuery +
          finalMinAreaQuery +
          finalSearchQuery +
          finalMaxAreaQuery ===
        "" ? null : (
          <div
            className="flex relative items-center justify-between px-2 py-2 min-w-fit bg-[#535353] text-white rounded-2xl mx-1 my-2 cursor-pointer shadow-lg max-md:m-2 max-md:max-w-fit private_area z-30"
            onClick={() => {
              setInfoNeighborhoodList(initialInfoNeighborhoodList);
              setCityList(["CITY"]);
              setNeighborhoodList(["NEIGHBORHOOD"]);
              setSubTypeList(["SUBTYPE"]);
              setStatusList(["STATUS"]);
              setBedroomsList(["BEDROOMS"]);
              setBathroomsList(["BATHROOMS"]);
              setGaragesList(["GARAGES"]);
              setVideoFilter(false);
              setExclusiveFilter(false);
              setMinPriceList(["minPrice"]);
              setMaxPriceList(["maxPrice"]);
              setMinAreaList(["minArea"]);
              setMaxAreaList(["maxArea"]);
              setCurrentPage(1);
              setOrderBy(0);
              setSearchText("");
              reload ? setReload(false) : setReload(true);
              window.history.replaceState(null, "/imovel");
              window.location.replace("/imovel");
              return;
            }}
          >
            <span className="px-1">Limpar Filtros</span>
          </div>
        )}
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center mt-[4rem] mb-20  ">
          <RiLoader2Line
            size={80}
            color="#b23437"
            className="animate-[spin_5s_linear_infinite]"
          />
        </div>
      ) : (
        <>
          <div className="pt-2 space-y-3 italic text-center">
            <div>
              {String(infoAptos?.numberResults).replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "1."
              )}{" "}
              Imóveis Encontrados
            </div>

            <div>
              <span>
                Página {currentPage} de{" "}
                {Math.ceil(infoAptos?.numberResults / 12)}
              </span>
            </div>
            <Pagination
              className="sticky z-40 justify-center"
              prev
              next
              first
              last
              size="xs"
              limit={12}
              total={infoAptos?.numberResults}
              activePage={currentPage}
              onChangePage={setCurrentPage}
              maxButtons={6}
            />
          </div>

          <section className="grid grid-cols-3 px-[10%] py-[2%] max-md:grid-cols-1 max-md:pl-[4%] max-md:pr-[2%] min-[2200px]:mx-[10%]">
            <Filter
              onClose={setOpen}
              setSearchText={setSearchText}
              setNeighborhoodList={setNeighborhoodList}
              setStatusList={setStatusList}
              setReload={setReload}
              setSubTypeList={setSubTypeList}
              setBedroomsList={setBedroomsList}
              setBathroomsList={setBathroomsList}
              setVideoFilter={setVideoFilter}
              setExclusiveFilter={setExclusiveFilter}
              setGaragesList={setGaragesList}
              setMinPrice={setMinPriceList}
              setMaxPrice={setMaxPriceList}
              setMinArea={setMinAreaList}
              setMaxArea={setMaxAreaList}
              setOrderBy={setOrderBy}
              setCurrentPage={setCurrentPage}
              setCity={setCityList}
              setinfoNeighborhood={setInfoNeighborhoodList}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              cities={cityList}
              infoCities={infoCities}
              infoNeighborhoodsList={infoNeighborhoodList}
              city={cityList}
              currentPage={currentPage}
              orderBy={orderBy}
              minAreaList={minAreaList}
              maxAreaList={maxAreaList}
              minPriceList={minPriceList}
              maxPriceList={maxPriceList}
              garagesList={garagesList}
              exclusiveFilter={exclusiveFilter}
              videoFilter={videoFilter}
              bathroomsList={bathroomsList}
              bedroomsList={bedroomsList}
              statusList={statusList}
              neighborhoodList={neighborhoodList}
              subTypeList={subTypeList}
              searchText={searchText}
              open={open}
              reload={reload}
            />

            {infoAptos?.properties.map((info, i) => {
              return (
                <>
                  <div className="bg-transparent w-[27%] h-[100%] mt-[-200px] z-20 fixed min-[768px]:hidden">
                    {" "}
                  </div>
                  <div className="bg-transparent w-[27%] h-[100%] mt-[-200px] z-20 right-10 fixed min-[768px]:hidden">
                    {" "}
                  </div>
                  <div className="flex flex-col justify-between mx-5 my-6 pb-6 space-y-2 rounded-xl border-solid border-2 border-[#F5F5F5] shadow-md max-w-[25.6rem] max-md:max-w-md max-md:ml-6 max-md:mr-8">
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      transitionTime={850}
                      swipeScrollTolerance={35}
                      preventMovementUntilSwipeScrollTolerance
                      infiniteLoop
                      centerMode
                      centerSlidePercentage={100}
                      className="flex items-center justify-center object-cover w-auto rounded-t-xl h-72 max-md:h-80 carousel-exclusivos-inner"
                      renderArrowNext={(clickHandler) =>
                        arrowFowardWhite({
                          onClickHandler: clickHandler,
                          size: 20,
                        })
                      }
                      renderArrowPrev={(clickHandler) =>
                        arrowPrevWhite({
                          onClickHandler: clickHandler,
                          size: 20,
                        })
                      }
                    >
                      {info.images.slice(0, 4).map((item, i) => {
                        return (
                          <div className="z-10 rounded-t-xl h-72 max-md:h-80">
                            {info.has_exclusivity && i === 0 ? (
                              <>
                                <div>
                                  <button className="bg-[#DAA14A] text-xs pointer-events-none fixed top-4 right-[-7rem] w-28 rounded-r-xl text-white pr-4 py-2 text-right">
                                    Exclusividade
                                  </button>
                                </div>
                              </>
                            ) : null}
                            <img
                              loading="lazy"
                              alt=""
                              src={item.thumbnail_url}
                              className="object-cover rounded-t-xl h-72 max-md:h-80"
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                    <div className="mt-0">
                      <div className="bg-[#ececec] mt-[-8px] p-2 flex items-center justify-center">
                        <RiMapPin2Fill
                          color="#b23437"
                          className="mt-1 mb-2 mr-1"
                          size={17}
                        />
                        {info?.address_neighborhood} - {info?.address_city}
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <span className="px-2 pt-5 pb-1 text-xl font-bold">
                        {"R$" +
                          info?.sale_value.replace(
                            /(\d)(?=(\d{3})+(?!\d))/g,
                            "$1."
                          )}
                      </span>
                      {info.meta_title ? (
                        <h6 className="px-10 pt-2 pb-3 text-sm font-normal text-center">
                          {info?.meta_title.length >= 40
                            ? `${info?.meta_title.substring(0, 40)}...`
                            : info?.meta_title}
                        </h6>
                      ) : (
                        <br />
                      )}
                    </div>
                    <div className="flex justify-center gap-4 mx-5 text-xs font-medium max-md:mx-2 max-md:gap-4">
                      <span
                        className={`flex items-center justify-center gap-1 `}
                      >
                        <BiArea size={"22px"} />
                        {info?.private_area ? (
                          <>{info?.private_area} m²</>
                        ) : (
                          <>Consulte</>
                        )}
                      </span>

                      {/* {info.private_area ? (
                          <span
                            className={`flex items-center justify-center gap-1 ${
                              info?.private_area ? "flex" : "hidden"
                            }`}
                          >
                            <BiArea size={"22px"} />
                            {info?.private_area} m²
                          </span>
                        ) : (
                          <br />
                        )} */}
                      <span
                        className={`items-center justify-center gap-1 flex`}
                      >
                        <BiSolidBed size={"22px"} />

                        {info?.bedrooms === 0 ? (
                          <>Consulte</>
                        ) : (
                          <>{info?.bedrooms} Dormitórios</>
                        )}
                      </span>
                      <span
                        className={`items-center justify-center gap-1 flex`}
                      >
                        <BiSolidCarGarage size={"22px"} />
                        {info?.garages === 0 ? (
                          <>Consulte</>
                        ) : (
                          <>{info?.garages} Vagas</>
                        )}
                      </span>
                    </div>
                    <div className="flex items-center justify-center gap-5">
                      <Button
                        label="Saiba mais"
                        link={`/imovel/${info?.identifier_code}`}
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </section>
          <section className="flex flex-col items-center justify-center pb-10">
            <span className="pb-5">
              Página {currentPage} de {Math.ceil(infoAptos?.numberResults / 12)}
            </span>
            <Pagination
              prev
              next
              first
              last
              size="xs"
              limit={12}
              total={infoAptos?.numberResults}
              activePage={currentPage}
              onChangePage={setCurrentPage}
              maxButtons={6}
            />
          </section>
        </>
      )}
    </>
  );
}
