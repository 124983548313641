import { HiOutlineAdjustmentsHorizontal, HiChevronUp } from "react-icons/hi2";
import btnStyle from "../btnStyle.module.css";
import { Dropdown, Nav, SelectPicker } from "rsuite";
import { Link } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Cities, Neighborhoods } from "../screens/Buy";
import { useLocation, useSearchParams } from "react-router-dom";

export function removeItem<T>(arr: Array<T>, value: T): Array<T> {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function isInArray(value: string, array: (string | null)[]) {
  return array.indexOf(value) > -1;
}

export const subtypesFilter = [
  "Apartamento",
  "Casa",
  "Cobertura",
  "Casa de Condomínio",
  "Geminado",
  "Loja",
  "Sala Comercial",
  "Sobrado",
  "Terreno",
  "Terreno Comercial",
  "Loft",
  "Studio",
  "Triplex",
  "Duplex",
  "Kitnet",
  "Outros",
];

export const neighborhoodFilter = [
  { label: "Centenário", value: -11851 },
  { label: "Centro", value: 35269 },
  { label: "Curtume", value: -12147 },
  { label: "Engenho Velho", value: -11848 },
  { label: "Getúlio Vargas", value: -11846 },
  { label: "Guarita", value: -11850 },
  { label: "Igra Norte", value: -11854 },
  { label: "Igra Sul", value: -11843 },
  { label: "Itapeva", value: -11849 },
  { label: "Morada das Palmeiras", value: -14788 },
  { label: "Praia Grande", value: -382 },
  { label: "Praia Itapeva", value: -12229 },
  { label: "Praia Paraíso", value: -12148 },
  { label: "Praia da Cal", value: -11844 },
  { label: "Prainha", value: -11847 },
  { label: "Predial", value: -12023 },
  { label: "Salinas", value: -14425 },
  { label: "São Francisco", value: -539 },
  { label: "Stan", value: -11845 },
  { label: "Vila São João", value: -11855 },
];

const orderByFilter = [
  { label: "Mais recentes", value: 0 },
  { label: "Maior valor", value: 1 },
  { label: "Menor valor", value: 2 },
  { label: "Maior área", value: 3 },
];

export function findOrderBy(n: number) {
  const selected = orderByFilter.filter((item) => item.value === n);
  return selected[0].label;
}

const statusFilter = ["Usado", "Novo", "Na planta", "Em construção"];

const tagPickerWidthNeighborhood = window.innerWidth > 768 ? 335 : 230;

let emptyInfoNeighborhoodList: Neighborhoods;

interface FilterBar {
  modalIsOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
  setSearchText: Dispatch<SetStateAction<string | null>>;
  setSubTypeList: Dispatch<SetStateAction<string[]>>;
  setNeighborhoodList: Dispatch<SetStateAction<string[]>>;
  setStatusList: Dispatch<SetStateAction<string[]>>;
  setOrderBy: Dispatch<SetStateAction<number>>;
  setCity: Dispatch<SetStateAction<string[]>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setinfoNeighborhood: Dispatch<React.SetStateAction<Neighborhoods>>;
  cities: String[];
  infoNeighborhoodsList: Neighborhoods;
  infoCities: Cities[];
  orderBy: number;
  searchText: string | null;
  reload: boolean;
  subTypeList: string[];
  neighborhoodList: string[];
  statusList: string[];
  isLoading: boolean;
}

export function FilterBar({
  modalIsOpen,
  setOpen,
  setSearchText,
  setReload,
  setSubTypeList,
  setNeighborhoodList,
  setStatusList,
  setOrderBy,
  setCity,
  setinfoNeighborhood,
  cities,
  infoNeighborhoodsList,
  orderBy,
  searchText,
  subTypeList,
  neighborhoodList,
  statusList,
  reload,
  isLoading,
  setIsLoading,
  infoCities,
}: FilterBar) {
  function findCity(thisValue: number) {
    const labelOfValue =
      infoCities === undefined
        ? undefined
        : infoCities.find((value) => value.city_id === thisValue);
    return labelOfValue?.city;
  }
  return (
    <>
      <section className="flex items-center justify-between">
        <Nav className="flex items-center gap-10 bg-[#eeeeee] py-4 pl-8 pr-6 w-full rounded-lg border-[#D1D1D1] border max-lg:hidden">
          <SelectPicker
            className="buy-filterbar"
            preventOverflow={false}
            appearance="subtle"
            cleanable
            onClean={() => {
              setCity(["CITY"]);
              setinfoNeighborhood(emptyInfoNeighborhoodList);
              setNeighborhoodList(["NEIGHBORHOOD"]);
            }}
            style={{
              width: tagPickerWidthNeighborhood - 180,
              float: "right",
              textAlign: "center",
            }}
            menuStyle={{
              width: tagPickerWidthNeighborhood - 180,
              float: "right",
            }}
            searchable={false}
            labelKey="city"
            valueKey="city_id"
            data={infoCities}
            placeholder={
              findCity(Number(cities[1])) === undefined ? (
                <span className="text-[#8e8e93]">Cidade</span>
              ) : (
                findCity(Number(cities[1]))
              )
            }
            loading={isLoading}
            onSelect={(item) => {
              setCity(["CITY", item]);
            }}
          />
          {/* <Dropdown
            className="min-w-fit dropdown"
            title={"Cidade"}
            trigger="hover"
          >
            {infoNeighborhoodsList?.address_neighborhood.map(
                  (neighborhoodSelected) => {
                    return (
                      <Dropdown.Item
                        onChange={() => {
                          reload ? setReload(false) : setReload(true);
                          neighborhoodList.includes(
                            `${neighborhoodSelected.neighborhood_id}`
                          )
                            ? removeItem(
                                neighborhoodList,
                                `${neighborhoodSelected.neighborhood_id}`
                              )
                            : setNeighborhoodList([
                                ...neighborhoodList,
                                `${neighborhoodSelected.neighborhood_id}`,
                              ]);
                        }}
                      >
                        <label className="flex items-center gap-2 pr-8">
                          <input
                            type="checkbox"
                            className="accent-[#b23437]"
                            checked={isInArray(
                              neighborhoodSelected.neighborhood_id.toString(),
                              neighborhoodList
                            )}
                          />
                          {neighborhoodSelected.neighborhood}
                        </label>
                      </Dropdown.Item>
                    );
                  }
                )}
          </Dropdown> */}
          <span className="text-[#b23437] font-semibold text-lg cursor-default">
            |
          </span>
          <Dropdown
            className="min-w-fit dropdown"
            title={"Bairros"}
            // trigger="hover"
          >
            {infoNeighborhoodsList === undefined ? (
              <span className="p-2">Selecione uma cidade</span>
            ) : (
              infoNeighborhoodsList?.address_neighborhood.map(
                (neighborhoodSelected) => {
                  return (
                    <Dropdown.Item
                      onChange={() => {
                        reload ? setReload(false) : setReload(true);
                        neighborhoodList.includes(
                          `${neighborhoodSelected.neighborhood_id}`
                        )
                          ? removeItem(
                              neighborhoodList,
                              `${neighborhoodSelected.neighborhood_id}`
                            )
                          : setNeighborhoodList([
                              ...neighborhoodList,
                              `${neighborhoodSelected.neighborhood_id}`,
                            ]);
                      }}
                    >
                      <label className="flex items-center gap-2 pr-8">
                        <input
                          type="checkbox"
                          className="accent-[#b23437]"
                          checked={isInArray(
                            neighborhoodSelected.neighborhood_id.toString(),
                            neighborhoodList
                          )}
                        />
                        {neighborhoodSelected.neighborhood}
                      </label>
                    </Dropdown.Item>
                  );
                }
              )
            )}
          </Dropdown>
          <span className="text-[#b23437] font-bold text-lg cursor-default">
            |
          </span>
          <Dropdown
            title={"Tipo de Imóvel"}
            className="min-w-fit dropdown"
            id="subtype"
            // trigger="hover"
          >
            {subtypesFilter.map((subtype) => {
              return (
                <Dropdown.Item
                  onChange={() => {
                    reload ? setReload(false) : setReload(true);
                    subTypeList.includes(`${subtype}`)
                      ? removeItem(subTypeList, `${subtype}`)
                      : setSubTypeList([...subTypeList, `${subtype}`]);
                  }}
                >
                  <label className="flex items-center gap-2 pr-8">
                    <input
                      type="checkbox"
                      className="accent-[#b23437]"
                      checked={isInArray(subtype, subTypeList)}
                    />
                    {subtype}
                  </label>
                </Dropdown.Item>
              );
            })}
          </Dropdown>
          <p className="text-[#D1D1D1] font-extralight text-lg scale-[3.5] scale-x-75 mt-[-9px] cursor-default">
            |
          </p>
          <form>
            <input
              value={searchText ? searchText : ""}
              type="text"
              placeholder="Nome ou Código do Imóvel"
              className="w-[200px] ml-[-20px] bg-[#eeeeee] outline-none"
              onChange={(event) => setSearchText(event.target.value)}
              onBlur={() => {
                return reload ? setReload(false) : setReload(true);
              }}
            />
            <Link
              to={`/imovel`}
              onClick={() => {
                return reload ? setReload(false) : setReload(true);
              }}
            >
              <input
                type="submit"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
                hidden
              />
            </Link>
          </form>

          <Link
            to={`/imovel`}
            className="flex items-center no-underline"
            onClick={() => {
              return reload ? setReload(false) : setReload(true);
            }}
          >
            <span className="bg-[#535353] text-white m-[-25px] p-[25px] rounded-r-lg">
              Buscar
            </span>
          </Link>
        </Nav>
        <div className="z-40 flex items-center justify-start gap-2 pl-6 max-md:pb-2">
          <button
            className={btnStyle.button2}
            onClick={() => {
              return modalIsOpen ? setOpen(false) : setOpen(true);
            }}
          >
            <span className="flex items-center justify-center gap-2 text-sm font-normal">
              <HiOutlineAdjustmentsHorizontal size={"30px"} />
              Filtros
            </span>
          </button>
          <Dropdown
            className="min-w-fit dropdown dropdown-filterbar border-solid border-[1px] rounded-md py-0.5 border-[#b23437]"
            title={"Ordernar por"}
            trigger="hover"
          >
            {orderByFilter.map((item) => {
              return (
                <Dropdown.Item
                  onClick={() => {
                    setOrderBy(item.value);
                    reload ? setReload(false) : setReload(true);
                  }}
                >
                  <label>{item.label}</label>
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        </div>
      </section>
      <section>
        <div className="items-center justify-center hidden mb-6 ml-7 max-lg:flex">
          <input
            className="inputSearchMobile"
            placeholder="Nome ou Cód do Imóvel"
            value={searchText ? searchText : ""}
            type="text"
            onChange={(event) => setSearchText(event.target.value)}
            onBlur={() => {
              return reload ? setReload(false) : setReload(true);
            }}
          />
          <Link
            to={"/imoveis"}
            onClick={() => {
              return reload ? setReload(false) : setReload(true);
            }}
          >
            <input
              className="submitSearchMobile"
              value="Buscar"
              type="submit"
              onClick={(e) => e.preventDefault()}
            />
          </Link>
        </div>
      </section>
    </>
  );
}
